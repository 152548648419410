import React from 'react';
import Layout from '../../../../components/Layout';
import Seo from '../../../../components/SEO';
import SectionIntro from '../../../../components/SectionIntro';
import {useStaticQuery, graphql} from 'gatsby';
import {getImage} from 'gatsby-plugin-image';
import BackgroundImage from 'gatsby-background-image';
import {convertToBgImage} from 'gbimage-bridge';
import BackGlossary from '../../../../components/BackGlossary';
import BannerRussel from '../../../../components/BannerRussel';

const GlossaryPageContent = () => {
  return (
    <div className="md:px-5 max-w-[1285px]">
      <div className="mb-8 text-xl font-bold text-left md:text-2xl">Identity Provider (IDP)</div>
      <p className="text-lg text-left">
        An identity provider (IDP) is responsible for storing and overseeing the digital identities of users. It can be
        compared to a guest list for digital and cloud-based applications, rather than a physical event. The IDP
        verifies user identities by means of username-password combinations and other factors, or it can simply provide
        a list of user identities for another service provider (such as a Single Sign-On) to verify.
      </p>
      <br></br>
      <p className="text-lg text-left">
        IDPs have the capability to authenticate not only human users but also any entity connected to a network or
        system, including computers and other devices. These entities stored by an IdP are referred to as "principals"
        instead of "users." However, IdPs are predominantly utilized in cloud computing environments for managing user
        identities.
      </p>
    </div>
  );
};

const IdpIdentityProvider = () => {
  const dataImages = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: {eq: "bg/rebrand/bg-main-top.png"}) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF], quality: 100)
        }
      }
    }
  `);
  const {placeholderImage} = dataImages;
  const image = getImage(placeholderImage);
  const bgImage = convertToBgImage(image);
  const title = `IDP`;
  const description = `Identity Provider`;
  return (
    <Layout seo={<Seo title={`Glossary: ${title} | Trustle`} description={description} />}>
      <BackgroundImage
        Tag="section"
        // Spread bgImage into BackgroundImage:
        {...bgImage}
        preserveStackingContext
        className="bg-cover"
      >
        <section className="">
          <div className="flex flex-col pt-16 mx-5 md:mx-8 lg:px-4 lg:max-w-[1920px] lg:mx-auto ">
            <SectionIntro isPageHeader hero="Glossary" title={title} description={`(${description})`} />
          </div>
        </section>

        <section className="pb-8">
          <div className="flex flex-col py-16 mx-5 md:mx-8 lg:px-4 lg:max-w-[1920px] lg:mx-auto">
            <div className=" max-w-[1360px] 3xl:max-w-[1420px] mx-auto text-blue">
              <div className="py-16">
                <BackGlossary />
              </div>
              <GlossaryPageContent />
            </div>
          </div>
        </section>
      </BackgroundImage>
      <section className="pb-16 ">
        <BannerRussel
          title="Curious how Trustle works?"
          description={'Get an inside look at Trustle. Schedule a demo with us today.'}
        />
      </section>
    </Layout>
  );
};

export default IdpIdentityProvider;
